// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
// Remove the @jsxRuntime classic directive as soon as react-scripts does not require this
/** @jsxRuntime classic */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./Datadog";

import { AppState, Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import History from "./services/History";
import IIQContextProvider from "./components/IIQContextProvider";
import React from "react";
import ReactDOM from "react-dom";
import config from "./config";

const onRedirectCallback = (appState?: AppState) => {
    History.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider {...config.auth0} redirectUri={window.location.origin} onRedirectCallback={onRedirectCallback}>
            <IIQContextProvider>
                <App />
            </IIQContextProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
