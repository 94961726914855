import { checkPremiumDueDate, policyStatusBuckets, reducer } from "../services/Lib";

import config from "../config";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const policyDataBreakdown = (policyData, OrganizationID) => {
    const activePolicies = policyData.filter((policy) => policy.Active);
    const inactivePolicies = policyData.filter((policy) => !policy.Active);

    const getPolicyCountByPremiumsDue = (dbName, value, range) =>
        activePolicies.filter((data) => data[dbName] !== value && checkPremiumDueDate(range, data.NextPremiumDueDate)).length;

    const getPolicyCountByPremiumsDueLITCO = (dbName, value, range) =>
        activePolicies.filter(
            (data) =>
                data[dbName] !== value &&
                !data.PolicyStatus.includes("Pending Approval", "Pre-Acceptance") &&
                checkPremiumDueDate(range, data.NextPremiumDueDate)
        ).length;

    const getPolicyCount = (dbName, value) => activePolicies.filter((data) => data[dbName] === value).length;

    const createPolicyStatusSections = () => {
        if (activePolicies.length || inactivePolicies.length) {
            const policyBreakdown = policyStatusBuckets.map((key) => {
                let total = 0;
                let totalDeathBenefit = 0;
                key.properties.forEach((property) => {
                    if (activePolicies.find((status) => status.PolicyStatus === property)) {
                        const matchingPolicies = activePolicies.filter((policy) => policy.PolicyStatus === property);
                        total += matchingPolicies.length;
                        totalDeathBenefit += parseInt(
                            matchingPolicies
                                .map((dataItem) => dataItem.NetDeathBenefit || 0)
                                .reduce(reducer)
                                .toFixed(0),
                            10
                        );
                    }
                });

                return {
                    name: key.name,
                    total,
                    totalDeathBenefit,
                    statusExplanation: key.statusExplanation
                };
            });

            policyBreakdown.push({
                name: "Inactive",
                total: inactivePolicies.length,
                totalDeathBenefit: inactivePolicies.length
                    ? inactivePolicies
                          .map((dataItem) => dataItem.NetDeathBenefit || 0)
                          .reduce(reducer)
                          .toFixed(0)
                    : 0,
                statusExplanation:
                    "Policies have one of the following statuses: Matured - Confirmed, Lapsed - Confirmed, Surrendered - Confirmed, Exchanged - Confirmed, Replaced - Confirmed, Converted - Confirmed, Death Claim - Confirmed, Transferred - Confirmed, Resigned as Trustee, Removed as Trustee,  Closed, Not Accepted"
            });

            return policyBreakdown;
        } // return null if no active or inactive policies exist on this portfolio
        return null;
    };

    const groupPolicyRatings = () => {
        const chartData = [
            { grade: "A", policies: 0 },
            { grade: "B", policies: 0 },
            { grade: "C", policies: 0 },
            { grade: "D", policies: 0 },
            { grade: "F", policies: 0 },
            { grade: "U", policies: 0 },
            { grade: "Pending", policies: 0 }
        ];

        if (activePolicies.length) {
            activePolicies.forEach((policy) => {
                if (policy.RiskRating) {
                    if (policy.RiskRating === "A+" || policy.RiskRating === "A" || policy.RiskRating === "A-") {
                        chartData[0].policies += 1;
                    } else if (policy.RiskRating === "B+" || policy.RiskRating === "B") {
                        chartData[1].policies += 1;
                    } else if (policy.RiskRating === "C") {
                        chartData[2].policies += 1;
                    } else if (policy.RiskRating === "D") {
                        chartData[3].policies += 1;
                    } else if (policy.RiskRating === "F") {
                        chartData[4].policies += 1;
                    } else if (policy.RiskRating.trim() === "U" || policy.RiskRating === "Unable to Complete") {
                        chartData[5].policies += 1;
                    }
                } else {
                    chartData[6].policies += 1;
                }
            });

            return chartData;
        }
        return null;
    };

    const groupDeathBenefit = () => {
        const chartData = [
            { deathBenefit: "0-500K", policies: 0, params: "0-500000" },
            { deathBenefit: "500K-1M", policies: 0, params: "500000-1000000" },
            { deathBenefit: "1M-5M", policies: 0, params: "1000000-5000000" },
            { deathBenefit: "5M-10M", policies: 0, params: "5000000-10000000" },
            { deathBenefit: "10M+", policies: 0, params: "10000000-10000000000000" }
        ];

        if (activePolicies.length) {
            activePolicies.forEach((policy) => {
                if (policy.NetDeathBenefit < 500000 || policy.NetDeathBenefit === null) {
                    chartData[0].policies += 1;
                } else if (policy.NetDeathBenefit >= 500000 && policy.NetDeathBenefit < 1000000) {
                    chartData[1].policies += 1;
                } else if (policy.NetDeathBenefit >= 1000000 && policy.NetDeathBenefit < 5000000) {
                    chartData[2].policies += 1;
                } else if (policy.NetDeathBenefit >= 5000000 && policy.NetDeathBenefit < 10000000) {
                    chartData[3].policies += 1;
                } else if (policy.NetDeathBenefit >= 10000000) {
                    chartData[4].policies += 1;
                }
            });

            return chartData;
        }
        return null;
    };

    const createCarrierGradesChart = () => {
        const carrierGradesChart = [
            { grade: "A", policies: 0 },
            { grade: "B", policies: 0 },
            { grade: "C", policies: 0 },
            { grade: "D", policies: 0 },
            { grade: "F", policies: 0 },
            { grade: "U", policies: 0 }
        ];
        if (activePolicies.length) {
            activePolicies.forEach((grade) => {
                if (grade.CarrierGrade === "A+" || grade.CarrierGrade === "A" || grade.CarrierGrade === "A-") {
                    carrierGradesChart[0].policies += 1;
                } else if (grade.CarrierGrade === "B+" || grade.CarrierGrade === "B") {
                    carrierGradesChart[1].policies += 1;
                } else if (grade.CarrierGrade === "C") {
                    carrierGradesChart[2].policies += 1;
                } else if (grade.CarrierGrade === "D") {
                    carrierGradesChart[3].policies += 1;
                } else if (grade.CarrierGrade === "F") {
                    carrierGradesChart[4].policies += 1;
                } else if (grade.CarrierGrade === "U") {
                    carrierGradesChart[5].policies += 1;
                }
            });

            return carrierGradesChart;
        }
        return null;
    };

    const createPolicyCategoryBreakdown = () => {
        if (activePolicies.length) {
            const policyCategoryBreakdown = [
                {
                    id: "Term",
                    label: "Term",
                    value: getPolicyCount("PolicyCategory", "Term")
                },
                {
                    id: "Whole",
                    label: "Whole",
                    value: getPolicyCount("PolicyCategory", "Whole")
                },
                {
                    id: "Universal",
                    label: "Universal",
                    value: getPolicyCount("PolicyCategory", "Universal")
                },
                {
                    id: "Variable",
                    label: "Variable",
                    value: getPolicyCount("PolicyCategory", "Variable")
                },
                {
                    id: "Annuity",
                    label: "Annuity",
                    value: getPolicyCount("PolicyCategory", "Annuity")
                }
            ];
            const policyCategoryTotal = policyCategoryBreakdown.map((obj) => obj.value).reduce(reducer);

            return policyCategoryBreakdown.map((policyCategory, index) => ({
                ...policyCategory,
                percent: `${Math.round((1000 * policyCategory.value) / policyCategoryTotal) / 10}%`,
                index
            }));
        }
        return null;
    };

    const createPremiumDueConsole = () => {
        if (activePolicies.length) {
            return [
                {
                    premiumStatusDisplay: "Gift Notices Less Than or Equal To 90 Days",
                    premiumStatus: "Gift Notices",
                    count: getPolicyCount("ACPremiumStatus", "Gift Notices"),
                    statusExplanation: "Gift Notices to be sent in or less than 90 days",
                    key: 0
                },
                {
                    premiumStatusDisplay: "Waiting for Gift",
                    premiumStatus: "Waiting for Gift",
                    count: getPolicyCount("ACPremiumStatus", "Waiting for Gift"),
                    statusExplanation: "We are waiting for gift.",
                    key: 1
                },
                {
                    premiumStatusDisplay: "Crummey Letters To Be Sent",
                    premiumStatus: "Crummey Letters",
                    count: getPolicyCount("ACPremiumStatus", "Crummey Letters"),
                    statusExplanation: "Amount of Crummey letters to be sent.",
                    key: 2
                },
                {
                    premiumStatusDisplay: "Pending Premium Payments",
                    premiumStatus: "Premium Payments",
                    count: getPolicyCount("ACPremiumStatus", "Premium Payments"),
                    statusExplanation: "Premium payments have not been paid yet.",
                    key: 3
                },
                {
                    premiumStatusDisplay: "Completed Premium Payments",
                    premiumStatus: "Completed Premium Payments",
                    count: getPolicyCount("ACPremiumStatus", "Completed Premium Payments"),
                    statusExplanation: "Policies have fully paid premiums for this billing cycle",
                    key: 4
                },
                {
                    premiumStatusDisplay: "Past Due Premiums",
                    premiumStatus: "Past Due Premiums",
                    count:
                        OrganizationID === config.litcoOrganizationID
                            ? // exclude Pre-Acceptance and Pending Approval for LITCO
                              getPolicyCountByPremiumsDueLITCO("ACPremiumStatus", "Completed Premium Payments", "Past Due Premiums")
                            : getPolicyCountByPremiumsDue("ACPremiumStatus", "Completed Premium Payments", "Past Due Premiums"),
                    statusExplanation: "Policies have premium payments past due.",
                    key: 5
                },
                {
                    premiumStatusDisplay: "Premiums Due 1-30 Days",
                    premiumStatus: "Premiums Due 1-30 Days",
                    count: getPolicyCountByPremiumsDue("ACPremiumStatus", "Completed Premium Payments", "Premiums Due 1-30 Days"),
                    statusExplanation: "Policies have premium payments due within 30 days.",
                    key: 6
                }
            ];
        }
        return null;
    };

    const policyBreakdown = createPolicyStatusSections();
    const policyRatingChart = groupPolicyRatings();
    const deathBenefitChart = groupDeathBenefit();
    const carrierGradesChart = createCarrierGradesChart();
    const policyCategoryBreakdown = createPolicyCategoryBreakdown();
    const premiumDueConsoleTotals = createPremiumDueConsole();

    return { policyBreakdown, premiumDueConsoleTotals, policyCategoryBreakdown, policyRatingChart, deathBenefitChart, carrierGradesChart };
};
