import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const applicationId = "2d1d213f-1090-4816-b035-dcc4a0489787";
const clientToken = "pubd2cb1f4c4384f44df20769e81e941ab2";
const service = "iiq-web";

const site = "datadoghq.com";
const env = (process.env.REACT_APP_ENVIRONMENT || "dev").toLowerCase();

datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
    allowedTracingUrls: [/https:\/\/cloud.*\.itm21st\.com\/.*/]
});

datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
});
