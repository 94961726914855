import { DropdownItem, LapseProjectionSummary, PolicyBreakdown, PolicyLapseProjectionData, TrustBreakdown } from "../types";
import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { filterSelector, getPortfolioListOptions } from "../services/Lib";

import { IIQContext } from "./IIQContextProvider";
import SimpleLoader from "./SimpleLoader";
import { TrustData } from "../features/TrustDetails/TrustDetails";
import { createLapseProjectionSummary } from "../services/PolicyLapseDataService";
import { policyDataBreakdown as createPolicyBreakdown } from "../services/PolicyDataService";
import { createTrustBreakdown } from "../services/TrustDataService";
import useSproc from "../hooks/useSproc";

interface PortfolioContextProps {
    trustData: TrustData[];
    policyData: PolicyData[];
    policyLapseProjectionData: PolicyLapseProjectionData[];
    lapseProjectionSummary: LapseProjectionSummary[];
    policyBreakdown: PolicyBreakdown[];
    trustBreakdown: TrustBreakdown[];
    documentApprovalQueue: DocumentApprovalData[];
    documentApprovalCount: number;
    loading: boolean;
    error: boolean;
    selectedClient?: number;
    setSelectedClient: (id?: number) => void;
    selectedTRM?: number;
    setSelectedTRM: (id?: number) => void;
    setTrustData: (data: TrustData[]) => void;
    clientOptions: DropdownItem[];
    trmOptions: DropdownItem[];
    onDocumentsApprovalQueueChange: (value: DocumentApprovalData[]) => void;
    unreadNotifications: number;
    setUnreadNotifications: (x: number) => void;
    setPolicyData: Dispatch<SetStateAction<PolicyData[]>>;
}

export const PortfolioContext = createContext<PortfolioContextProps>({
    trustData: [],
    policyData: [],
    policyLapseProjectionData: [],
    lapseProjectionSummary: [],
    policyBreakdown: [],
    trustBreakdown: [],
    documentApprovalQueue: [],
    documentApprovalCount: 0,
    loading: true,
    error: false,
    clientOptions: [],
    trmOptions: [],
    setSelectedClient: () => {
        throw new Error("Not implemented");
    },
    setSelectedTRM: () => {
        throw new Error("Not implemented");
    },
    setTrustData: () => {
        throw new Error("Not implemented");
    },
    onDocumentsApprovalQueueChange: () => {
        throw new Error("Not implemented");
    },
    unreadNotifications: 0,
    setUnreadNotifications: () => {
        throw new Error("Not implemented");
    },
    setPolicyData: () => {
        throw new Error("Not Implemented");
    }
});

interface PortfolioProviderProps {
    children: React.ReactNode;
}

export const PortfolioProvider: React.FC<PortfolioProviderProps> = (props: PortfolioProviderProps) => {
    const { children } = props;
    const { appData } = useContext(IIQContext);
    const sproc = useSproc();

    const [trustData, setTrustData] = useState<TrustData[]>([]);
    const [policyData, setPolicyData] = useState<PolicyData[]>([]);
    const [policyLapseProjectionData, setPolicyLapseProjectionData] = useState<PolicyLapseProjectionData[]>([]);
    const [lapseProjectionSummary, setLapseProjectionSummary] = useState<LapseProjectionSummary[]>([]);
    const [policyBreakdown, setPolicyBreakdown] = useState<PolicyBreakdown[]>([]);
    const [trustBreakdown, setTrustBreakdown] = useState<TrustBreakdown[]>([]);
    const [documentApprovalData, setDocumentApprovalData] = useState<DocumentApprovalData[]>([]);
    const [documentApprovalQueue, setDocumentApprovalQueue] = useState<DocumentApprovalData[]>([]);
    const [documentApprovalCount, setDocumentApprovalCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [selectedClient, setSelectedClient] = useState<number>();
    const [selectedTRM, setSelectedTRM] = useState<number>();
    const [clientOptions, setClientOptions] = useState<DropdownItem[]>([]);
    const [trmOptions, setTRMOptions] = useState<DropdownItem[]>([]);
    const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

    useEffect(() => {
        if (!appData) {
            setSelectedClient(undefined);
            setSelectedTRM(undefined);
            setClientOptions([]);
            setTRMOptions([]);
            return;
        }
        (async () => {
            setLoading(true);
            try {
                const promises = [
                    sproc("GetTrustData"),
                    sproc("GetPolicyLapseProjections"),
                    sproc("GetPolicies"),
                    sproc("GetClientDocumentApprovalRequests"),
                    sproc("GetTRMs"),
                    sproc("GetUnreadNotificationsCount")
                ];
                const [trusts, policyLapseProjections, policies, documentApprovals, trms, [notifications]] = await Promise.all(promises);
                setTrustData(trusts);
                setPolicyData(policies);
                setPolicyLapseProjectionData(policyLapseProjections);
                setLapseProjectionSummary(createLapseProjectionSummary(policyLapseProjections));
                setTrustBreakdown(createTrustBreakdown(trusts));
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setPolicyBreakdown(createPolicyBreakdown(policies, appData.organization.OrganizationID) as any);
                setDocumentApprovalData(
                    documentApprovals.map((doc, key: number) => ({ ...doc, isSelected: false, popupOpen: false, key }))
                );
                setDocumentApprovalQueue(
                    documentApprovals.map((doc, key: number) => ({ ...doc, isSelected: false, popupOpen: false, key }))
                );
                setDocumentApprovalCount(
                    documentApprovals.filter((doc: { ApprovalStatus: string }) => doc.ApprovalStatus === "Pending Approval").length
                );
                setClientOptions(getPortfolioListOptions(appData.clients));
                setTRMOptions(
                    trms.map((trm: { RelationshipManagerContactID: number; FileAs: string }) => ({
                        key: trm.RelationshipManagerContactID,
                        text: trm.FileAs,
                        value: trm.RelationshipManagerContactID
                    }))
                );
                setUnreadNotifications(notifications.Count);
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [appData, sproc]);

    useEffect(() => {
        setTrustBreakdown(createTrustBreakdown(filterSelector(trustData, selectedTRM, selectedClient)));
        setLapseProjectionSummary(createLapseProjectionSummary(filterSelector(policyLapseProjectionData, selectedTRM, selectedClient)));
        setTrustBreakdown(createTrustBreakdown(filterSelector(trustData, selectedTRM, selectedClient)));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setPolicyBreakdown(
            createPolicyBreakdown(filterSelector(policyData, selectedTRM, selectedClient), appData?.organization.OrganizationID) as any
        );
        setDocumentApprovalQueue(filterSelector(documentApprovalData, selectedTRM, selectedClient));
        setDocumentApprovalCount(
            filterSelector(documentApprovalData, selectedTRM, selectedClient).filter((doc) => doc.ApprovalStatus === "Pending Approval")
                .length
        );
    }, [
        appData?.organization.OrganizationID,
        documentApprovalData,
        policyData,
        policyLapseProjectionData,
        selectedClient,
        selectedTRM,
        trustData
    ]);

    const onDocumentsApprovalQueueChange = (docsToUpdate: DocumentApprovalData[]) => {
        const newApprovalData = documentApprovalData.map((doc) => {
            const changedDoc = docsToUpdate.find((d) => d.DocumentApprovalRequestID === doc.DocumentApprovalRequestID);
            if (changedDoc) {
                return changedDoc;
            }
            return doc;
        });
        setDocumentApprovalQueue(
            newApprovalData.sort((a, b) => new Date(a.DateOfNextAction).getTime() - new Date(b.DateOfNextAction).getTime())
        );
        setDocumentApprovalCount(newApprovalData.filter((doc) => doc.ApprovalStatus === "Pending Approval").length);
        setDocumentApprovalData(newApprovalData);
    };

    if (loading) {
        return <SimpleLoader />;
    }

    return (
        <PortfolioContext.Provider
            value={{
                trustData,
                policyData,
                policyLapseProjectionData,
                lapseProjectionSummary,
                policyBreakdown,
                trustBreakdown,
                documentApprovalQueue,
                documentApprovalCount,
                loading,
                error,
                selectedClient,
                selectedTRM,
                clientOptions,
                trmOptions,
                setSelectedClient,
                setSelectedTRM,
                onDocumentsApprovalQueueChange,
                setTrustData,
                setPolicyData,
                unreadNotifications,
                setUnreadNotifications
            }}
        >
            {children}
        </PortfolioContext.Provider>
    );
};

export interface PolicyData {
    PolicyID: number;
    IIQNumber: string;
    PolicyNumber: string;
    GrossDeathBenefit: number | null;
    NetDeathBenefit: number | null;
    TotalGrossCashValue: number | null;
    OutstandingLoanBalance: number | null;
    RiskRating: string | null;
    NextPremiumDueDate: string | null;
    TrustAccountNumber: string | null;
    ACPremiumStatus: string | null;
    PolicyStatus: string | null;
    PolicyCategory: string | null;
    PolicyType: string | null;
    CarrierID: number;
    CarrierGrade: string;
    CarrierName: string | null;
    ClientID: number;
    RelationshipManagerContactID: number | null;
    TrustID: number;
    TrustName: string;
    Active: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DocumentApprovalData = any;
