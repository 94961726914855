import { Divider, Grid, Header, Message } from "semantic-ui-react";
import React from "react";
import { RestrictedFeature } from "../../types";

interface StandardUpgradeProps {
    feature: RestrictedFeature;
}

const StandardUpgrade: React.FC<StandardUpgradeProps> = (props: StandardUpgradeProps) => {
    const { feature } = props;
    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h1" color="blue" size="huge">
                        Standard Upgrade
                    </Header>
                    <Divider style={{ marginTop: "0.5rem" }} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={1}>
                <Grid.Column width={9} textAlign="center">
                    <Message>
                        <Message.Header>Your current InsuranceIQ servicing package does not include the {feature} feature.</Message.Header>
                        <p>For more information, please contact your customer service representative at 866-384-2766.</p>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default StandardUpgrade;
