import { Header, Icon } from "semantic-ui-react";
import React, { useContext } from "react";
import { RestrictedFeature, RoleRestrictedFeature } from "../types";

import { IIQContext } from "./IIQContextProvider";
import { Redirect } from "react-router-dom";
import StandardUpgrade from "../features/StandardUpgrade/StandardUpgrade";
import { roleCanAccess } from "../services/Lib";

const UserRoleCheck: React.FC = () => (
    <Header textAlign="center" color="grey" content="Checking user access..." icon={<Icon name="circle notch" loading />} />
);

interface WithAuthorizationRequiredProps {
    /** User restrictions for specific features, redirect to an upgrade page displaying this feature as a target for upgrade. Example `"Document Approval"` */
    restrictedFeature?: RestrictedFeature;
    /** If user is not allowed to access the given page based on user role, redirect back to portfolio dashboard */
    roleRestrictedPage?: RoleRestrictedFeature;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const withAuthorizationRequired = <P extends {}>(Component: React.ComponentType<P>, options: WithAuthorizationRequiredProps): React.FC<P> =>
    function Wrapper(props: P): JSX.Element {
        const { restrictedFeature, roleRestrictedPage } = options;
        const { appData, litcoAuthorizedOrganization } = useContext(IIQContext);

        // User restrictions - apply additional restrictions for restricted features
        if (restrictedFeature) {
            if (!appData) {
                return <UserRoleCheck />;
            }
            const { AdminConsole, IsManaged } = appData.organization;
            if (restrictedFeature === "Admin Console") {
                if (IsManaged) {
                    return <Redirect to="/portfolio" />;
                }
                if (!AdminConsole && !IsManaged) {
                    return <StandardUpgrade feature={restrictedFeature} />;
                }
            }
            if (restrictedFeature === "Document Approval" && !IsManaged) {
                return <StandardUpgrade feature={restrictedFeature} />;
            }
            if (restrictedFeature === "Death Claim" && !litcoAuthorizedOrganization) {
                return <Redirect to="/portfolio" />;
            }
            if (restrictedFeature === "Deposit Slip" && !IsManaged) {
                return <Redirect to="/portfolio" />;
            }
            if (restrictedFeature === "Trust Onboard" && IsManaged) {
                return <Redirect to="/portfolio" />;
            }
        }

        // Role-restricted pages
        if (roleRestrictedPage) {
            if (!appData) {
                return <UserRoleCheck />;
            }
            if (!roleCanAccess(roleRestrictedPage, appData.role)) {
                return <Redirect to="/portfolio" />;
            }
        }

        return <Component {...props} />;
    };

export default withAuthorizationRequired;
