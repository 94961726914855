import { DropdownItem, ListItem, Role, RoleAccess, RoleRestrictedFeature, UserClientData } from "../types";
import { clone, isString, mapValues } from "lodash";

import { durationInYears } from "@progress/kendo-date-math";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

export const currencyFormatterDecimal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const percentageFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

export const percentageFormatterDecimal = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const activePolicyStatuses: string[] = [
    "In Force",
    "Matured - Pending",
    "Pending - Approval",
    "Pre-Acceptance",
    "Lapsed - Pending Planned",
    "Lapsed - Pending Unplanned",
    "Surrendered - Pending",
    "Exchanged - Pending",
    "Replaced - Pending",
    "Converted - Pending",
    "Death Claim - Pending",
    "Transferred - Pending",
    "Pending Resignation",
    "Pending Removal",
    "Pending Closing",
    "Pending Approval"
];

export const inactivePolicyStatuses: string[] = [
    "Matured - Confirmed",
    "Lapsed - Confirmed",
    "Surrendered - Confirmed",
    "Exchanged - Confirmed",
    "Replaced - Confirmed",
    "Converted - Confirmed",
    "Death Claim - Confirmed",
    "Transferred - Confirmed",
    "Resigned as Trustee",
    "Removed as Trustee",
    "Closed",
    "Not Accepted"
];

export const stringDays: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const stringMonths: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const trustStatusBuckets = [
    { name: "Active", properties: ["Active"], statusExplanation: "Trusts are currently active." },
    {
        name: "Pending Additions",
        properties: ["Pending Approval"],
        statusExplanation: "Newly opened trusts need approval to become active"
    },
    {
        name: "Pending Closing",
        properties: ["Pending Removal", "Pending Resignation", "Pending Closing"],
        statusExplanation: "Trusts are one of the following statuses: Pending Removal, Pending Resignation, Pending Closing"
    }
];

export const policyStatusBuckets = [
    { name: "In Force", properties: ["In Force"], statusExplanation: "Policies are currently active" },
    {
        name: "Pending Additions",
        properties: ["Pending Approval", "Pre-Acceptance", "Transferred - Pending"],
        statusExplanation: "Policies are one of the following statuses: Pending Approval, Pre-Acceptance, Transferred - Pending"
    },
    {
        name: "Carrier Disbursement - Pending",
        properties: ["Surrendered - Pending", "Death Claim - Pending", "Matured - Pending"],
        statusExplanation: "Policies are one of the following statuses: Surrendered - Pending, Death Claim - Pending, Matured - Pending"
    },
    {
        name: "Pending Closing",
        properties: [
            "Lapsed - Pending Planned",
            "Lapsed - Pending Unplanned",
            "Exchanged - Pending",
            "Replaced - Pending",
            "Converted - Pending",
            "Pending Resignation",
            "Pending Removal",
            "Pending Closing"
        ],
        statusExplanation:
            "Policies are one of the following statuses: Lapsed - Pending Planned, Lapsed - Pending Unplanned, Exchanged - Pending, Replaced - Pending, Converted - Pending, Pending Resignation, Pending Removal, Pending Closing"
    }
];
export const activeTrustStatuses = ["Active", "Pending Approval", "Pending Resignation", "Pending Removal", "Pending Closing"];

export const inactiveTrustStatuses = [
    "Resigned as Trustee",
    "Removed as Trustee",
    "Closed",
    "Not Accepted",
    "Surrendered - Confirmed",
    "Lapsed - Confirmed",
    "Matured - Confirmed"
];

export const createStringDate = (dateToConvert: Date): string => {
    const month = stringMonths[dateToConvert.getMonth()];
    const date = dateToConvert.getDate();
    const day = stringDays[dateToConvert.getDay()];
    const year = dateToConvert.getFullYear();
    return `${day}, ${month} ${date}, ${year}`;
};

export const dateDiff = (date1: Date, date2: Date): number => {
    const month1 = date1.getMonth();
    const day1 = date1.getDate();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    let years = durationInYears(date1, date2);
    if (month1 > month2 || (month1 === month2 && day1 > day2)) {
        years -= 1;
    }
    return years;
};

export const dateFormatter = (ufDate: string): string =>
    ufDate ? `${ufDate.substring(5, 7)}/${ufDate.substring(8, 10)}/${ufDate.substring(0, 4)}` : "";

export const timeFormatter = (ufDate: string): string => {
    let time = ufDate.substring(11, 19);
    let meridiem: "AM" | "PM";
    let hours;
    if (parseInt(time.substring(0, 2), 16) < 12) {
        meridiem = "AM";
    } else {
        hours = +time.substring(0, 2) - 12;
        time = hours.toString() + time.slice(2);
        meridiem = "PM";
    }

    return `${time} ${meridiem}`;
};

export const toISODateString = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const getIsoFormDate = (date: string): string => {
    if (!date) {
        return "";
    }
    return date.split("T")[0];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const reducer = (accumulator: any, currentValue: any): any => accumulator + currentValue;

export const boolToYesNo = (value: boolean | null): "Yes" | "No" => (value ? "Yes" : "No");
export const boolToYesNoNP = (value: boolean | null): string => {
    switch (value) {
        case true:
            return "Yes";
        case false:
            return "No";
        default:
            return "N/P";
    }
};

export const maskSSN = (SSN: string, maskCharacter?: string): string | null => {
    if (!SSN) {
        return null;
    }
    const lastFour = SSN.substring(SSN.length - 4);
    const localMaskChar = maskCharacter || "X";
    return `${[localMaskChar, localMaskChar, localMaskChar].join("")}-${[localMaskChar, localMaskChar].join("")}-${lastFour}`;
};

// type options are 'long' (January), 'narrow' (J), 'short' (Jan), '2-digit' (01)
export const getMonths = (type: "long" | "narrow" | "short" | "2-digit"): string[] =>
    Array.from({ length: 12 }, (x, index) => new Date(0, index).toLocaleDateString("en-US", { month: type }));

export const yesNoNpFormatter = (value: number | null): string => {
    switch (value) {
        case 1:
            return "Yes";
        case 2:
            return "No";
        default:
            return "N/P";
    }
};

export const yesNoBooleanOptions = (): DropdownItem[] => {
    return [
        {
            text: "Yes",
            key: "Yes",
            value: true
        },
        {
            text: "No",
            key: "No",
            value: false
        }
    ];
};

export const yesNoNpOptions = (): DropdownItem[] => {
    return [
        {
            text: "Yes",
            key: "Yes",
            value: 1
        },
        {
            text: "No",
            key: "No",
            value: 2
        },
        {
            text: "N/P",
            key: "N/P",
            value: 3
        }
    ];
};

export const yesNpNaFormatter = (value: number): string => {
    switch (value) {
        case 1:
            return "Yes";
        case 2:
            return "N/P";
        default:
            return "N/A";
    }
};

export const filterSelector = (dataArray: any[], trmID?: number, clientID?: number): any[] => {
    let filteredArray = dataArray;
    if (trmID) {
        filteredArray = filteredArray.filter((item) => item.RelationshipManagerContactID === trmID);
    }
    if (clientID) {
        filteredArray = filteredArray.filter((item) => item.ClientID === clientID);
    }
    return filteredArray;
};

export const convertBufferToBase64 = (buffer: Buffer): string => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const kendoDateFormat = "{0: MM/dd/yyyy}";
export const kendoDateTimeFormat = "{0: M/d/yyyy, h:mm a}";
export const kendoCurrencyFormat = "{0:C}";

/**
 * Mutates an array of objects by parsing the selected fields as Date objects
 * @param {Object[]} data - An array of objects
 * @param {string[]} dateFields - An array of field names to be parsed as Date objects
 */
export const parseDates = (data: any[], fields: string[]): any[] => {
    const parsed = data.map((row) => {
        const mutableRow = { ...row };
        fields.forEach((field) => {
            if (mutableRow[field]) {
                const dateWithoutZ = row[field].slice(0, -1);
                mutableRow[field] = new Date(dateWithoutZ);
            }
        });
        return mutableRow;
    });
    return parsed;
};

export const checkPremiumDueDate = (range: string, nextDueDate: string | Date): boolean => {
    let inRange = false;
    const currentDate = new Date();
    const thirtyDaysOut = new Date(clone(currentDate).setDate(currentDate.getDate() + 30));
    const myDate = new Date(nextDueDate);

    switch (range) {
        case "Past Due Premiums":
            inRange = myDate <= currentDate;
            break;
        case "Premiums Due 1-30 Days":
            inRange = myDate > currentDate && myDate <= thirtyDaysOut;
            break;
        default:
            inRange = false;
    }
    return inRange;
};

export const replaceSpecialCharacters = (stringToCheck: string): string =>
    stringToCheck
        .replace(/&amp;apos;/gi, "'")
        .replace(/&lt;/gi, "<")
        .replace(/&gt;/gi, ">")
        .replace(/&quot;/gi, `"`)
        .replace(/&apos;/gi, `'`)
        .replace(/&amp;/gi, "&")
        .replace(/&rsquo;/gi, "'")
        .replace(/&ndash;/gi, "--");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const cleanDataObj = (data: any) =>
    mapValues(data, (value) => {
        if (!isString(value)) {
            return value;
        }
        return replaceSpecialCharacters(value);
    });

export const cleanDataArray = <T>(dataArr: T[] | undefined): T[] => {
    if (!dataArr) {
        return [];
    }
    return dataArr.map((dataItem) => cleanDataObj(dataItem));
};

export const getPortfolioListOptions = (clients: UserClientData[]): DropdownItem[] => {
    const options: DropdownItem[] = clients.map((c) => {
        return {
            key: c.ClientID,
            text: `${c.CommonName}${c.IsInnovestPortfolio ? "*" : ""}`,
            value: c.ClientID
        };
    });

    return cleanDataArray(options);
};

export const thirtyMinuteTimeout = 1800000;

export const isTrustManaged = (clientID: number, clientsOnUser: any[]): boolean =>
    clientsOnUser.find((c) => c.ClientID === clientID).IsManaged;
export const isTrustPolicyManaged = (clientID: number, clientsOnUser: any[]): boolean =>
    clientsOnUser.find((c) => c.ClientID === clientID).IsManaged;

export const buildSettingsObject = (settingsArray: any[]): any => {
    const settingsObject: any = {};

    settingsArray.forEach((setting: any) => {
        if (setting.SettingValue) {
            if (setting.SettingName === "AdminFeeDueDate") {
                const pipeIndex: number = setting.SettingValue.indexOf("|");
                if (pipeIndex > -1) {
                    settingsObject.AdminFeeDueDateOption = parseInt(setting.SettingValue.substring(0, pipeIndex));
                    settingsObject.AdminFeeDueDateValue = setting.SettingValue.substring(pipeIndex + 1);
                } else {
                    settingsObject.AdminFeeDueDateOption = parseInt(setting.SettingValue);
                    settingsObject.AdminFeeDueDateValue = "";
                }
            } else {
                const intValue: number | null = parseInt(setting.SettingValue);
                if (isFinite(intValue)) {
                    settingsObject[setting.SettingName] = intValue;
                } else {
                    if (setting.SettingName.includes("mergefield")) {
                        settingsObject[setting.SettingName] = setting.SettingValue;
                    } else {
                        settingsObject[setting.SettingName] = setting.SettingValue.toLowerCase();
                    }
                }
            }
        }
    });
    return settingsObject;
};

export const letterSignatoryTypes: DropdownItem[] = [
    { text: "Administrator", value: 1, key: 1 },
    { text: "TRM", value: 2, key: 2 },
    { text: "TA", value: 3, key: 3 },
    { text: "CA", value: 4, key: 4 },
    { text: "Trustee", value: 5, key: 5 }
];

export const trustTypeOptions: DropdownItem[] = [
    { text: "Trust", value: "trust", key: "trust" },
    { text: "Non-Trust", value: "nontrust", key: "nontrust" }
];

export const yesNoOptions: DropdownItem[] = [
    { text: "Yes", value: "yes", key: "yes" },
    { text: "No", value: "no", key: "no" }
];

export const noDataMsg = "No Data Available";

export const formatRemainderPaidBy = (options: ListItem[], remainderPaidBy: string | null): string | null => {
    // Data allows for multiple values, and can contain either string values ('Automatic Premium Loan, Dividiends Reduce') that are comma delimited,
    // or ListItemID numbers ('317|319|') that are delimited by pipes.
    if (remainderPaidBy) {
        if (isNaN(parseInt(remainderPaidBy.substring(0)))) {
            return remainderPaidBy;
        }
        const remainders = remainderPaidBy.split("|");
        const textOptions = options
            .filter((o: ListItem) => remainders.find((r: string) => o.ListItemID === parseInt(r)))
            .map((r: any) => r.ItemText);
        return textOptions.join(", ");
    }
    return null;
};

export const firstLetterUppercase = (input: string): string => {
    return input.charAt(0).toUpperCase() + input.slice(1);
};

export const scrubHtml = (html: string): string => {
    const scrubbed: string = replaceSpecialCharacters(html.replace(/<[^>]*>/g, " ").trim());
    return scrubbed;
};

export const adminConsoleStepDictionary: any = {
    "Gift Notices": 1,
    "Waiting for Gift": 2,
    "Crummey Letters": 3,
    "Premium Payments": 4,
    "Completed Premium Payments": 5
};

export const batchDownloadLimit = 100;

export type PremiumMode = "Annual" | "Quarterly" | "Semi-Annual" | "Monthly" | "Single Premium";

export const calculateAnnualizedPremium = (mode: PremiumMode, value: number): number => {
    switch (mode) {
        case "Annual":
            return value * 1 || 0;
        case "Quarterly":
            return value * 4 || 0;
        case "Semi-Annual":
            return value * 2 || 0;
        case "Monthly":
            return value * 12 || 0;
        case "Single Premium":
            return value || 0;
        default:
            return value * 1;
    }
};

export const formatDateForFilename = (): string => {
    const today = new Date().toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
    return `${today.substring(6)}${today.substring(0, 2)}${today.substring(3, 5)}`;
};

export const sectionSplitter = "<!--SECTION SPLIT-->";

export const dataTypeOptions = [
    { text: "Text", value: 1 },
    { text: "Percentage", value: 2 },
    { text: "Currency", value: 3 },
    { text: "Date", value: 4 }
];

export const roleAccess: RoleAccess = {
    "Super Admin": [],
    Administrator: ["Organization"],
    "Trust Advisor": [
        "Organization",
        "Portfolio",
        "Branch",
        "TrustEdit",
        "PolicyEdit",
        "Reports",
        "TrustOnboard",
        "AdminConsole",
        "CloselyHeldBusiness",
        "CloselyHeldBusinesses",
        "WillEdit"
    ]
};

export const roleCanAccess = (page: RoleRestrictedFeature, role: Role | undefined): boolean => {
    return !!role && !roleAccess[role].includes(page);
};

export const redact = (stringToRedact: string, charactersToKeep: number): string => {
    const redacted: any = [];
    const hyphens: any = [];
    const numArray: any = [];
    stringToRedact.split("").forEach((number, index) => {
        if (/[-/,.()&'":;]/.test(number)) {
            hyphens.push({ symbol: number, index });
        } else {
            numArray.push(number);
        }
    });
    numArray.forEach((number: string, index: number) => {
        if (index >= numArray.length - charactersToKeep) {
            redacted.push(number);
        } else {
            number = "x";
            redacted.unshift(number);
        }
    });
    hyphens.forEach((hyphen: any) => {
        redacted.splice(hyphen.index, 0, hyphen.symbol);
    });
    return redacted.join("");
};

export const calculateNextPremiumDueDate = (currentPremiumDueDate: Date | string, premiumMode: string): string | null => {
    if (!premiumMode || !currentPremiumDueDate) {
        return null;
    }
    let nextPremiumDueDate: any = new Date(currentPremiumDueDate);

    switch (premiumMode) {
        case "Annual":
            nextPremiumDueDate = nextPremiumDueDate.setFullYear(nextPremiumDueDate.getFullYear() + 1);
            break;
        case "Monthly":
            nextPremiumDueDate = nextPremiumDueDate.setMonth(nextPremiumDueDate.getMonth() + 1);
            break;
        case "Quarterly":
            nextPremiumDueDate = nextPremiumDueDate.setMonth(nextPremiumDueDate.getMonth() + 3);
            break;
        case "Semi-Annual":
            nextPremiumDueDate = nextPremiumDueDate.setMonth(nextPremiumDueDate.getMonth() + 6);
            break;
        case "Single Premium":
            nextPremiumDueDate = "";
            break;
        default:
            nextPremiumDueDate = "";
            break;
    }
    if (nextPremiumDueDate) {
        return dateFormatter(new Date(nextPremiumDueDate).toISOString());
    }
    return nextPremiumDueDate;
};
