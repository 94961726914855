// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createLapseProjectionSummary = (policyLapseProjectionData) => {
    const lapseProjectionSummary = [
        { key: 0, LapseCategory: "< 2 Years", policies: 0, TotalNetDeathBenefit: 0, categoryShorthand: "0-1" },
        { key: 1, LapseCategory: "2-5 Years", policies: 0, TotalNetDeathBenefit: 0, categoryShorthand: "2-5" },
        { key: 2, LapseCategory: "6-15 Years", policies: 0, TotalNetDeathBenefit: 0, categoryShorthand: "6-15" },
        { key: 3, LapseCategory: "> 15 Years", policies: 0, TotalNetDeathBenefit: 0, categoryShorthand: "15-1000" }
    ];

    policyLapseProjectionData.forEach((policy) => {
        if (policy.ProjectedLapseYear <= 1) {
            lapseProjectionSummary[0].policies += 1;
            lapseProjectionSummary[0].TotalNetDeathBenefit += parseInt(policy.NetDeathBenefit, 10);
        } else if (policy.ProjectedLapseYear >= 2 && policy.ProjectedLapseYear <= 5) {
            lapseProjectionSummary[1].policies += 1;
            lapseProjectionSummary[1].TotalNetDeathBenefit += parseInt(policy.NetDeathBenefit, 10);
        } else if (policy.ProjectedLapseYear >= 6 && policy.ProjectedLapseYear <= 15) {
            lapseProjectionSummary[2].policies += 1;
            lapseProjectionSummary[2].TotalNetDeathBenefit += parseInt(policy.NetDeathBenefit, 10);
        } else if (policy.ProjectedLapseYear > 15) {
            lapseProjectionSummary[3].policies += 1;
            lapseProjectionSummary[3].TotalNetDeathBenefit += parseInt(policy.NetDeathBenefit, 10);
        }
    });
    return lapseProjectionSummary;
};
