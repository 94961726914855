import { IIQContext } from "../components/IIQContextProvider";
import { RoleRestrictedFeature } from "../types";
import { roleCanAccess } from "../services/Lib";
import { useContext } from "react";

/** Returns true if the user's role can access the given `RoleRestrictedFeature` */
const useCanAccessFeature = (page: RoleRestrictedFeature): boolean => {
    const { appData } = useContext(IIQContext);
    return roleCanAccess(page, appData?.role);
};

export default useCanAccessFeature;
