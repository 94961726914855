import React, { Suspense, lazy } from "react";
import { Redirect, Router, Switch } from "react-router-dom";

import { Container } from "semantic-ui-react";
import History from "./services/History";
import NavBar from "./components/NavBar";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import SimpleLoader from "./components/SimpleLoader";
import withAuthorizationRequired from "./components/WithAuthorizationRequired";

const AdminConsole = lazy(() => import("./features/AdminConsole/AdminConsole"));
const CarrierDetails = lazy(() => import("./features/CarrierDetails/CarrierDetails"));
const CarrierSummary = lazy(() => import("./features/CarrierSummary/CarrierSummary"));
const ClientDetails = lazy(() => import("./features/ClientDetails/ClientDetails"));
const CloselyHeldBusinesses = lazy(() => import("./features/CloselyHeldBusiness/CloselyHeldBusinesses"));
const CloselyHeldBusiness = lazy(() => import("./features/CloselyHeldBusiness/CloselyHeldBusinessDashboard"));
const ContactDetails = lazy(() => import("./features/ContactDetails/ContactDetails"));
const DepositDashboard = lazy(() => import("./features/DepositSlipQueue/DepositDashboard"));
const DeathClaim = lazy(() => import("./features/DeathClaim/DeathClaim"));
const DeathClaimDashboard = lazy(() => import("./features/DeathClaimQueue/DeathClaimDashboard"));
const DocumentApproval = lazy(() => import("./features/DocumentApproval/DocumentApproval"));
const DocumentManagementPage = lazy(() => import("./features/DocumentManagement/DocumentManagementPage"));
const Notifications = lazy(() => import("./features/Notifications/Notifications"));
const OrganizationSummary = lazy(() => import("./features/OrganizationSummary/OrganizationSummary"));
const PolicyDetails = lazy(() => import("./features/PolicyDetails/PolicyDetails"));
const PolicyGridData = lazy(() => import("./features/PolicyGrid/PolicyGridData"));
const Portfolio = lazy(() => import("./features/Portfolio/Portfolio"));
const Report = lazy(() => import("./features/Report/ReportContextProvider"));
const Search = lazy(() => import("./features/Search/Search"));
const StandardUpgrade = lazy(() => import("./features/StandardUpgrade/StandardUpgrade"));
const TaxQueueDashboard = lazy(() => import("./features/TaxQueue/TaxQueueDashboard"));
const TrustDetails = lazy(() => import("./features/TrustDetails/TrustDetails"));
const TrustGridData = lazy(() => import("./features/TrustGrid/TrustGridData"));
const TrustOnboard = lazy(() => import("./features/TrustOnboard/TrustOnboard"));
const Wills = lazy(() => import("./features/Will/Wills"));
const Will = lazy(() => import("./features/Will/Will"));

const AppRoutes: React.FC = () => {
    return (
        <Router history={History}>
            <ScrollToTop />
            <NavBar />
            <Container style={{ marginTop: "5em", width: "auto" }}>
                <Suspense fallback={<SimpleLoader />}>
                    <Switch>
                        <PrivateRoute
                            path="/admin-console"
                            component={withAuthorizationRequired(AdminConsole, {
                                roleRestrictedPage: "AdminConsole",
                                restrictedFeature: "Admin Console"
                            })}
                        />
                        <PrivateRoute path="/carrier" exact component={CarrierSummary} />
                        <PrivateRoute path="/carrier/:CarrierID" component={CarrierDetails} />
                        <PrivateRoute
                            path="/closely-held-business"
                            exact
                            component={withAuthorizationRequired(CloselyHeldBusinesses, { roleRestrictedPage: "CloselyHeldBusinesses" })}
                        />
                        <PrivateRoute
                            path="/closely-held-business/:closelyHeldBusinessID/:companyName"
                            exact
                            component={withAuthorizationRequired(CloselyHeldBusiness, { roleRestrictedPage: "CloselyHeldBusiness" })}
                        />
                        <PrivateRoute path="/contact/:contactId" component={ContactDetails} />
                        <PrivateRoute
                            path="/death-claim/:deathClaimID"
                            exact
                            component={withAuthorizationRequired(DeathClaim, { restrictedFeature: "Death Claim" })}
                        />
                        <PrivateRoute
                            path="/death-claim-queue"
                            exact
                            component={withAuthorizationRequired(DeathClaimDashboard, { restrictedFeature: "Death Claim" })}
                        />
                        <PrivateRoute
                            path="/deposits"
                            exact
                            component={withAuthorizationRequired(DepositDashboard, { restrictedFeature: "Deposit Slip" })}
                        />
                        <PrivateRoute
                            path="/document-approval"
                            component={withAuthorizationRequired(DocumentApproval, {
                                restrictedFeature: "Document Approval"
                            })}
                        />
                        <PrivateRoute path="/document-management" component={DocumentManagementPage} />
                        <PrivateRoute path="/notifications" component={Notifications} />
                        <PrivateRoute
                            path="/onboarding"
                            component={withAuthorizationRequired(TrustOnboard, {
                                roleRestrictedPage: "TrustOnboard",
                                restrictedFeature: "Trust Onboard"
                            })}
                        />
                        <PrivateRoute
                            path="/organization"
                            component={withAuthorizationRequired(OrganizationSummary, { roleRestrictedPage: "Organization" })}
                        />
                        <PrivateRoute path="/policy/:policyId" component={PolicyDetails} />
                        <PrivateRoute path="/policygrid" component={PolicyGridData} />
                        <PrivateRoute path="/portfolio" exact component={Portfolio} />
                        <PrivateRoute
                            path="/portfolio/:clientId"
                            component={withAuthorizationRequired(ClientDetails, { roleRestrictedPage: "Portfolio" })}
                        />
                        <PrivateRoute path="/reports" component={withAuthorizationRequired(Report, { roleRestrictedPage: "Reports" })} />
                        <PrivateRoute path="/search" component={Search} />
                        <PrivateRoute path="/standardUpgrade" component={StandardUpgrade} />
                        <PrivateRoute path="/trust/:trustId" component={TrustDetails} />
                        <PrivateRoute path="/trustgrid" component={TrustGridData} />
                        <PrivateRoute path="/will/:willId" component={Will} />
                        <PrivateRoute path="/will" component={Wills} />
                        <PrivateRoute
                            path="/tax-queue"
                            exact
                            component={withAuthorizationRequired(TaxQueueDashboard, { restrictedFeature: "Tax Return" })}
                        />

                        <Redirect to="/portfolio" />
                    </Switch>
                </Suspense>
            </Container>
        </Router>
    );
};

export default AppRoutes;
