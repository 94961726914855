import { Grid, Message } from "semantic-ui-react";

import React from "react";
import config from "../config";

const UserDataErrorFallback: React.FC = () => {
    return (
        <Grid padded>
            <Grid.Row centered columns={1}>
                <Grid.Column width={9} textAlign="center">
                    <Message negative>
                        <Message.Header>Oops! Something is amiss.</Message.Header>
                        <p>Your credentials are correct but this user seems to be inactive in our system.</p>
                        <p>
                            For more information, contact your account administrator{" "}
                            <a href={`mailto:${config.supportEmailAddress}`}>{config.supportEmailAddress}</a> or{" "}
                            <a href={`tel:${config.supportPhoneNumber}`}>{config.supportPhoneNumber}</a>.
                        </p>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default UserDataErrorFallback;
