import { activeTrustStatuses, inactiveTrustStatuses, trustStatusBuckets } from "../services/Lib";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createTrustBreakdown = (trustData) => {
    const activeTrusts = trustData.filter((trust) => activeTrustStatuses.includes(trust.TrustStatus));
    const inactiveTrusts = trustData.filter((trust) => inactiveTrustStatuses.includes(trust.TrustStatus));

    if (!activeTrusts.length && !inactiveTrusts.length) {
        return [];
    }

    const trustBreakdown = trustStatusBuckets.map((key) => {
        let total = 0;
        key.properties.forEach((property) => {
            if (activeTrusts.find((status) => status.TrustStatus === property)) {
                const matchingTrusts = activeTrusts.filter((trust) => trust.TrustStatus === property);
                total += matchingTrusts.length;
            }
        });
        return {
            name: key.name,
            total,
            statusExplanation: key.statusExplanation
        };
    });

    trustBreakdown.push({
        displayName: "Inactive",
        name: "Inactive",
        total: inactiveTrusts.length,
        statusExplanation: "Trusts are of the following statuses: Resigned as Trustee, Removed as Trustee, Closed, Not Accepted"
    });

    return trustBreakdown.map((trust, i) => ({ ...trust, key: i }));
};
