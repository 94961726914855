import { Dimmer, Loader, Segment, SemanticSIZES } from "semantic-ui-react";

import React from "react";

interface SimpleLoaderProps {
    message?: string;
    size?: SemanticSIZES;
}

const SimpleLoader: React.FC<SimpleLoaderProps> = (props: SimpleLoaderProps) => {
    const { message, size } = props;
    return (
        <Segment style={{ height: "90vh" }}>
            <Dimmer active inverted>
                <Loader indeterminate size={size ?? "massive"} content={message} />
            </Dimmer>
        </Segment>
    );
};

export default SimpleLoader;
