import { useCallback, useContext } from "react";

import { IIQContext } from "../components/IIQContextProvider";
import { SprocParameters } from "@itm21st/databroker";
import { cleanDataArray } from "../services/Lib";
import useDatabroker from "./useDatabroker";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SprocFunction = <T = any>(sprocName: string, sprocParams?: SprocParameters) => Promise<T[]>;

const useSproc = (): SprocFunction => {
    const databroker = useDatabroker();
    const { appData } = useContext(IIQContext);

    const sproc = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async <T = any>(sprocName: string, sprocParams?: SprocParameters): Promise<T[]> => {
            const OrganizationID = appData?.organization.OrganizationID;

            const result = await databroker.sproc<T>({
                objectName: sprocName,
                parameters: {
                    OrganizationID: OrganizationID ? OrganizationID : null,
                    ...sprocParams
                }
            });
            const { data, error } = result;
            if (error) {
                console.error(error);
                throw new Error(error);
            }
            const cleaned = cleanDataArray(data);
            return cleaned;
        },
        [appData, databroker]
    );

    return sproc;
};

export default useSproc;
